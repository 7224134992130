<template>
  <div></div>
</template>

<script>
// 这是给后端提供的模板页面
/*第三方授权的整体逻辑如下：
     客户会点击一个链接  https://apigw-uat.cloudcc.com/tfsuat/services/oauth2/authorize?client_id=4GywOFHwwOhCy8Pb8Mdqm2yQH&response_type=code&scope=full&state=111wssdw&orgId=org08f84e9c0566eaf0c
     后端处理 重定向到本页面（前端提供的路由地址）并携带state参数和auto参数
    * https://ltnuat.cloudcc.cn/#/thirdPartyLoginAuth?auto=true&state=TE9HSU5kY0ZCNGU%3D&orgId=org08f84e9c0566eaf0c
    * 没有token的情况下 直接跳到登录  调登录接口，登录之后跳到授权页面进行授权
    *   有token的情况下 auto为true时  需要用公司业务token去请求第三方登录 通过返回数据判断 如果授权了直接到第三方url 如果没授权 携带第三方token state 到授权页面进行授权
    *                  auto为false时---》到已有用户页面   1、点用户名  需要用公司业务token去请求第三方登录 通过返回数据判断 如果授权了直接到第三方链接 如果没授权 携带第三方token state 到授权页面进行授权
                                                        2、点其他用户登录 到登录页 登录之后跳到授权页面进行授权
    *
    * */
import * as thirdLoginAuthApi from "./api";
export default {
  name: "index",
  mounted() {
    // 隐藏加载框
    document.getElementById("lightning-loading").style.display = "none";
    this.init();
  },
  methods: {
    /**
     * 对象转formdata格式 方法
     * @param  obj 对象数据
     * @returns  转为formdata格式的数据
     */
    jsToFormData(obj) {
      const formData = new FormData();
      Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof Array) {
          obj[key].forEach((item) => {
            formData.append(key, item);
          });
          return;
        }
        formData.append(key, obj[key]);
      });
      return formData;
    },
    init() {
      let url = window.location.href;
      // 截取问号后面的部分  state=111&auto=true
      let searchUrl = url.split("?")[1];
      // 将url解析成对象的形式 {state: '111', auto: 'true'}
      let paramsObj = this.$qs.parse(searchUrl);
      // 获取token  公司业务token
      // let token = this.$CCDK.CCToken.getToken();
      //先用localStorage
      let token = localStorage.getItem("binding")
      if (!token) {
        // 没有token直接跳转到登录
        this.$router.push({
          path: "/thirdLogin",
          query: {
            state: paramsObj.state,
            orgId: paramsObj.orgId || ""
          },
        });
      } else {
        // 有token的情况
        // auto为true表示自动登录 此时需要请求接口做一下操作
        // 根据公司业务token去请求接口 判断此用户有没有授权 以及 获取现有用户的第三方token、state等
        // 如果已授权 直接到第三方   没有授权 携带接口返回的专用token  state到授权页面
        if (paramsObj.auto == "true") {
          let params = {
            accessToken: token,
            state: paramsObj.state,
            isMd5: "false",
            language: localStorage.getItem("locale"),
            orgId: paramsObj.orgId || ""
          };
          // 使用公司业务token获取第三方的token等信息  同时判断此用户是否授权
          thirdLoginAuthApi
            .thirdPartyLoginAuth(this.jsToFormData(params))
            .then((res) => {
              // auto为true表示自动登录
              if (res.data != null) {
                // 没授权
                if (res.data.result) {
                  this.$router.push({
                    path: "/thirdAuthorization",
                    query: {
                      state: res.data.data.state,
                      token: res.data.data.accessToken,
                      username: res.data.data.username,
                      orgId: paramsObj.orgId || ""
                    },
                  });
                } else {
                  this.$message.error(res.data.returnInfo);
                }
              } else {
                // data中没有返回数据  返回数据在headers中
                // 授权了
                let headers = res.headers;
                if (headers.enableredirect == "true") {
                  window.location.href = `${headers.url}?state=${headers.state}&code=${headers.code}`;
                }
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        } else {
          // auto为false表示不自动登录 直接跳转已有用户页面
          this.$router.push({
            path: "/thirdLogged",
            query: {
              state: paramsObj.state,
              token: token,
              orgId: paramsObj.orgId || ""
            },
          });
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
